import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/smacss/",
  "date": "2015-12-30",
  "title": "SMACSS",
  "author": "admin",
  "tags": ["development", "css", "sass"],
  "featuredImage": "feature.jpg",
  "excerpt": "SMACSS stands for Scalable and Modular Architecture for CSS. It is pronounced “smacks” and introduced by Jonathan Snook."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why SMACSS?`}</h2>
    <p><inlineCode parentName="p">{`SMACSS`}</inlineCode>{` is a design guideline, and not a rigid framework. SMACSS is an attempt to document a consistent approach to site development when using CSS. It strives to achieve two main goals:`}</p>
    <ul>
      <li parentName="ul">{`Increase the semantic value of a section of html and content`}</li>
      <li parentName="ul">{`Decrease the expectation of a `}<inlineCode parentName="li">{`specific html structure`}</inlineCode></li>
    </ul>
    <Message type="info" title="Categorization" content="At the very core of SMACSS is categorization. By categorizing CSS rules, we begin to see patterns and can define better practices around each of these patterns." mdxType="Message" />
    <h2>{`More on Categorization`}</h2>
    <p>{`The purpose of this categorization is less code repetition, a more consistent experience, and easier maintenance. Under SMACSS there are 5 general categories of css rules.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Base`}</strong>{` - Your defaults (html, body, h1, ul, etc)`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout`}</strong>{` - Divides the page into major sections`}</li>
      <li parentName="ul"><strong parentName="li">{`Module`}</strong>{` - Reusable modular components of a design`}</li>
      <li parentName="ul"><strong parentName="li">{`State`}</strong>{` - Describe how things look when in a particular state (hidden or expanded, active/inactive)`}</li>
      <li parentName="ul"><strong parentName="li">{`Theme`}</strong>{` - Defines things like a color scheme or typographic treatment across a site`}</li>
    </ul>
    <p>{`Instead of mixing CSS across all these categories, we can apply some guidelines across these categories and simplify our CSS.`}</p>
    <h2>{`Naming Convention`}</h2>
    <p>{`Jonathan suggests adding prefixes for the selectors by following a convention:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Base`}</strong>{` - Nothing needed`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout`}</strong>{` - `}<inlineCode parentName="li">{`l-`}</inlineCode>{` or `}<inlineCode parentName="li">{`layout-`}</inlineCode>{` prefixes`}</li>
      <li parentName="ul"><strong parentName="li">{`Module`}</strong>{` - Modules just use module name (eg: `}<inlineCode parentName="li">{`.tooltip`}</inlineCode>{`) instead of trying to prefix each, however related modules receive a consistent prefix to help organize them`}</li>
      <li parentName="ul"><strong parentName="li">{`State`}</strong>{` - `}<inlineCode parentName="li">{`is-`}</inlineCode>{` prefix as in is-active or is-hidden`}</li>
      <li parentName="ul"><strong parentName="li">{`Theme`}</strong>{` - Defines things like a color scheme or typographic treatment across a site`}</li>
    </ul>
    <p>{`Let's expand on this a little bit more.`}</p>
    <h2>{`Base`}</h2>
    <p>{`Base rules are applied for elements. Not `}<inlineCode parentName="p">{`id`}</inlineCode>{`s or `}<inlineCode parentName="p">{`class`}</inlineCode>{`es.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h1 {
  color: red;
}

body {
  margin: 0 auto;
}
`}</code></pre>
    <p>{`CSS resets are an example of base styles. `}<inlineCode parentName="p">{`CSS Reset`}</inlineCode>{` and `}<inlineCode parentName="p">{`Normalize.css`}</inlineCode>{` are example libararies using base element styling to override user agent (browser) in-built style.`}</p>
    <h2>{`Layout`}</h2>
    <p>{`There are two types of layout components:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Major`}</strong>{` - Like a `}<inlineCode parentName="li">{`header`}</inlineCode>{` block containing a logo and a title`}</li>
      <li parentName="ul"><strong parentName="li">{`Minor`}</strong>{` - Like a logo that is inside a header tag.`}</li>
    </ul>
    <p>{`SMACSS layout rules will only apply for `}<inlineCode parentName="p">{`major components`}</inlineCode>{`. Minor components fall under module rules.`}</p>
    <p>{`Generally a layout style will have a single selector (either an ID or class). Additionally there could be a style set to allow the layout style to respond to different factors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`#Banner {
  width: 100%;
}
.l-top #Banner {
  width: 800px;
}
`}</code></pre>
    <h2>{`Module`}</h2>
    <p>{`Modules should be designed so they can exist on their own, which gives them greater flexibility in being combined and moved around to different parts of the design without breaking the layout. With modules we do want to avoid IDs and element selectors. More reuse means classes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  width: 250px;
}
.box p {
  padding: 20px;
}
#Banner .box {
  width: 100px;
}
`}</code></pre>
    <h2>{`State`}</h2>
    <p>{`A state style is one that augments or overrides other styles under given conditions. For example an accordion with collapsed and expanded states. These are typically applied to the same element, should be built to stand alone, and are usually developed on a single class selector.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  width: 250px;
}
.box.is-selected {
  border: 1px solid red;
}
`}</code></pre>
    <p>{`State changes are represented in one of three ways:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Class name`}</strong>{` — The change happens by adding or removing a class, usually with JavaScript`}</li>
      <li parentName="ul"><strong parentName="li">{`Pseudo-class`}</strong>{` — The change happens to elements that are descendants or siblings of the element with the pseudo-class`}</li>
      <li parentName="ul"><strong parentName="li">{`Media query`}</strong>{` — The change happens under defined criteria, such as different viewport sizes.`}</li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`SMACSS helps us write more flexible and maintainable CSS.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      